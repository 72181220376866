











































































































import {
    Component,
    Vue,
    Prop,
    Watch,
} from 'vue-property-decorator';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import Occupancy from '@/modules/occupancy/components/occupancy.vue';
import * as _ from 'lodash';
import DATA_TYPE from './constants/data-type.constant';
import {
    ICell,
    IColumn,
    ICellSize,
    ITooltip,
} from './interfaces';
import CiTableCell from './ci-table-cell.vue';
import Demand from '../ui-kit/demand.vue';
import CustomMultiSelect, { Item } from '../ui-kit/custom-multi-select.vue';

@Component({
    components: {
        CiTableCell, CalendarEventsContainer, Demand, Occupancy, CustomMultiSelect,
    },
    name: 'CiTableColumn',
})
export default class CiTableColumn extends Vue {
    @Prop({
        required: true,
    })
    columnData!: IColumn;

    @Prop({
        required: false,
    })
    cellSize?: ICellSize;

    @Prop({
        required: true,
        type: [Number, Array],
    })
    columnIndex!: number | number[];

    @Prop({
        type: Number,
        required: true,
    })
    columnsLength!: number;

    @Prop({
        type: Array,
        default: () => [],
    })
    disabledColumns!: string[];

    @Prop({
        type: Boolean,
        default: true,
    })
    isLoading!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    isSubColumn!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    growLastColumn!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    isBlank!: boolean;

    private selectedColumns: Item[] = this.multiselectItems;

    get occupancyTitle(): string | null {
        return _.get(this.columnData, ['options', 'occupancy', 'title'], null);
    }

    get occupancyPercent(): string {
        return _.get(this.columnData, ['options', 'occupancy', 'percent'], 0.7);
    }

    @Watch('columnData.dynamicColumns')
    onDynamicColumnsChange(cur: string[], prev: string[]) {
        const newColumns = cur?.filter(col => !prev?.includes(col));
        if (!this.columnData.dynamicColumns || !newColumns.length) {
            return;
        }
        const activeColumns = this.columnData.dynamicColumns.filter(column => !this.disabledColumns.includes(column));
        this.selectedColumns = activeColumns.map(column => ({
            name: column,
            value: column,
        }));
    }

    hasSubColumns() {
        if (typeof this.columnData.data[0] === 'string') {
            return false;
        }

        const data = this.columnData.data as ICell[] | IColumn[];
        if (data[0] && (data[0] as IColumn).title) {
            return true;
        }
        return false;
    }

    cellWidth(columnIndex: number | number[], isMinWidth: boolean = false) {
        if (!this.cellSize) {
            return undefined;
        }
        let columnsLength = this.isSubColumn ? this.columnsLength - this.disabledColumns.length : this.columnsLength;

        if (!isMinWidth && this.growLastColumn) {
            if (Array.isArray(columnIndex) && columnIndex[1] === columnsLength - 1) {
                return 'auto';
            }
            if (columnIndex === columnsLength - 1) {
                return 'auto';
            }
        }

        if (isMinWidth && this.columnData.headerStyle && this.columnData.headerStyle['min-width']) {
            return this.columnData.headerStyle['min-width'];
        }

        if (Array.isArray(columnIndex)) {
            let [parentIndex, index] = columnIndex as number[];
            if ((this.cellSize.width.length - 1) < parentIndex) {
                parentIndex = this.cellSize.width.length - 1;
            }

            if ((this.cellSize.width[parentIndex].length - 1) < index) {
                index = this.cellSize.width[parentIndex].length - 1;
            }

            return this.cellSize.width[parentIndex][index];
        }

        let index = columnIndex;

        if (columnIndex > (this.cellSize.width.length - 1)) {
            index = this.cellSize.width.length - 1;
        }

        const colSize = this.cellSize.width[index as number];

        if (typeof colSize === 'string') {
            return colSize;
        }

        columnsLength = this.columnData.data.length - this.disabledColumns.length;

        const unit = colSize[0].replace(/\d+/g, '');
        const size = colSize.reduce((acc, value, i) => {
            if (i > columnsLength - 1 && i > 0) {
                return acc;
            }

            return acc + parseInt(value, 10);
        }, 0);

        return size + unit;
    }

    cellHeight(rowIndex: number) {
        if (!this.cellSize) {
            return undefined;
        }

        if (rowIndex > (this.cellSize.height.length - 1)) {
            return this.cellSize.height[this.cellSize.height.length - 1];
        }

        return this.cellSize.height[rowIndex];
    }

    handleLeaveCell() {
        this.$emit('leaveCell');
    }

    handleHoverCell(domRect: DOMRect, index: number) {
        this.$emit('hoverRow', {
            rowIndex: index, columnIndex: this.columnIndex, isSubColumn: this.isSubColumn, domRect,
        });
    }

    handleHoverColumn(payload: { rowIndex: number, columnIndex: number, showTooltip: boolean, domRect: DOMRect }) {
        this.$emit('hoverRow', payload);
    }

    handleTooltipChange(e: ITooltip) {
        this.$emit('tooltipChange', e);
    }

    handleColumnsChange(e: Item[]) {
        this.selectedColumns = [...e];
        const disabledColumns = this.columnData.dynamicColumns!
            .filter(column => !this.selectedColumns
                .find(selected => selected.value === column));
        return this.$emit('disableColumns', disabledColumns);
    }

    isColumnDisabled(title: string) {
        return this.disabledColumns.find(disabledColumn => disabledColumn === title);
    }

    get isOccupancy() {
        return this.columnData.dataType === DATA_TYPE.OCCUPANCY;
    }

    get isDemand() {
        return this.columnData.dataType === DATA_TYPE.DEMAND;
    }

    get isEvent() {
        const { dataType } = this.columnData;
        return dataType && dataType === DATA_TYPE.EVENT;
    }

    get multiselectItems(): Item[] {
        if (!this.columnData.dynamicColumns || !this.columnData.dynamicColumns.length) {
            return [];
        }
        return this.columnData.dynamicColumns.map(column => ({
            name: column,
            value: column,
        }));
    }

    get emptyColumnData() {
        return {
            title: '',
            data: new Array(28).map(i => ''),
        } as IColumn;
    }
}
